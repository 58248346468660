// dropdown
document.addEventListener("DOMContentLoaded", function () {
  const dropdown = document.querySelector(".lead_card_dropdown");
  const toggle = document.querySelector(".lead_card_dropdown_toggle");

  toggle.addEventListener("click", function (event) {
    event.stopPropagation();
    dropdown.classList.toggle("open");
  });

  document.addEventListener("click", function (event) {
    if (!dropdown.contains(event.target)) {
      dropdown.classList.remove("open");
    }
  });
});

const swiper2 = new Swiper(".mySwiper2", {
  spaceBetween: 30,
  effect: "fade",
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

// input location address
document.addEventListener("DOMContentLoaded", function () {
    const input = document.getElementById("input_location_address");
    const icon = document.querySelector(".input_location_icon");

    input.addEventListener("input", function () {
        if (input.value.trim() !== "") {
            icon.style.display = "block"; 
        } else {
            icon.style.display = "none"; 
        }
    });

    icon.addEventListener("click", function () {
        const url = input.value.trim();
        if (url) {
            const validUrl = url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;
            window.open(validUrl, "_blank");
        }
    });
});

// remowing classes from crm_two
if (window.location.pathname.includes("crm_two")) {
    document.querySelector(".add_user_button").classList.remove("active_button");
    document.querySelector('.header_for_crm_two').style.padding = "1.65vw 2.5vw 1.65vw 2.52vw"
}

document.addEventListener('DOMContentLoaded', function () {
  const submitButton = document.querySelector('.comment-submit-js');
  const lastStep = parseInt(document.querySelector('meta[name="last-step"]').content, 10) || 0;

  const startStep = lastStep === 0 ? 0 : lastStep;

  const swiperInstance = document.querySelector('.mySwiper2')?.swiper;
  if (!swiperInstance) {
    return;
  }

  swiperInstance.slideTo(startStep);

  if (submitButton) {
    submitButton.addEventListener('click', function (event) {
      event.preventDefault();

      const currentStep = swiperInstance.realIndex;

      const stepInput = document.getElementById(`step_${currentStep}`);
      const textarea = document.getElementById(`message_${currentStep}`);

      if (!stepInput || !textarea) {
        return;
      }

      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

      const formData = new FormData();
      formData.append('brand_partner_comment[step]', stepInput.value);
      formData.append('brand_partner_comment[message]', textarea.value);

      submitButton.disabled = true;

      async function submitForm() {
        try {
          const response = await fetch(submitButton.closest('form').action, {
            method: 'POST',
            body: formData,
            headers: {
              'X-CSRF-Token': csrfToken,
              'X-Requested-With': 'XMLHttpRequest'
            },
          });

          if (!response.ok) {
            throw new Error('Помилка при відправці форми');
          }

          const nextStep = currentStep + 1;
          if (swiperInstance && !swiperInstance.animating) {
            if (nextStep < swiperInstance.slides.length) {
              swiperInstance.slideTo(nextStep);
            }
          }

        } catch (error) {
          console.error(error);
        } finally {
          submitButton.disabled = false;
        }
      }

      submitForm();
    });
  }
});
