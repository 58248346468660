var popupCreate = document.querySelector('.create-lead')
var popupEdit = document.querySelector('.edit-lead')
var popupBg = document.querySelector('.popup-bg')
var closeBtnCreate = document.querySelector('.close-create')
var closeBtnEdit = document.querySelector('.close-edit')
var openBtnCreate = document.querySelector('.open-lead-popup-create')
var openBtnEdit = document.querySelector('.open-lead-popup-edit')

if (openBtnCreate) {
    openBtnCreate.addEventListener('click', function() {
        popupCreate.classList.add('active');
        popupBg.classList.add('active');
    })

    closeBtnCreate.addEventListener('click', function() {
        popupCreate.classList.remove('active');
        popupBg.classList.remove('active');
    })

    popupBg.addEventListener('click', function() {
        popupCreate.classList.remove('active');
        popupBg.classList.remove('active');
    })
}

if (openBtnEdit) {
    openBtnEdit.addEventListener('click', function() {
        popupEdit.classList.add('active');
        popupBg.classList.add('active');
    })

    closeBtnEdit.addEventListener('click', function() {
        popupEdit.classList.remove('active');
        popupBg.classList.remove('active');
    })

    popupBg.addEventListener('click', function() {
        popupEdit.classList.remove('active');
        popupBg.classList.remove('active');
    })
}


document.addEventListener("DOMContentLoaded", () => {
    document.addEventListener("input", (event) => {
        const input = event.target;
        const group = input.closest(".input-group.action-buttons");
        if (!group) return;

        if (!group.historyStack) {
            group.historyStack = [];
            group.redoStack = [];
            group.lastSavedValue = "";
            group.saveTimeout = null;
        }

        const currentValue = input.value;

        clearTimeout(group.saveTimeout);
        group.saveTimeout = setTimeout(() => {
            if (group.historyStack.length === 0 || group.historyStack[group.historyStack.length - 1] !== currentValue) {
                group.historyStack.push(currentValue);
                group.lastSavedValue = currentValue;
                group.redoStack = [];
                updateButtonsState(group);
            }
        }, 1000);
    });

    document.addEventListener("focusin", (event) => {
        const input = event.target.closest(".input-group.action-buttons input[type='text']");
        if (input) {
            const group = input.closest(".input-group.action-buttons");
            const actionBox = group.querySelector(".action-buttons-box");
            actionBox.classList.add("visible");
        }
    });

    document.addEventListener("focusout", (event) => {
        setTimeout(() => {
            const input = event.target.closest(".input-group.action-buttons input[type='text']");
            if (input) {
                const group = input.closest(".input-group.action-buttons");
                if (!group.contains(document.activeElement)) {
                    const actionBox = group.querySelector(".action-buttons-box");
                    actionBox.classList.remove("visible");
                }
            }
        }, 100);
    });

    document.addEventListener("click", (event) => {
        const btn = event.target;
        const group = btn.closest(".input-group.action-buttons");
        if (!group) return;

        const input = group.querySelector("input[type='text']");

        if (!group.historyStack) group.historyStack = [];
        if (!group.redoStack) group.redoStack = [];

        if (btn.classList.contains("undoBtn")) {
            if (group.historyStack.length > 1) {
                group.redoStack.push(group.historyStack.pop());
                input.value = group.historyStack[group.historyStack.length - 1] || "";
                group.lastSavedValue = input.value;
            }
        }

        if (btn.classList.contains("redoBtn")) {
            if (group.redoStack.length > 0) {
                let restoredValue = group.redoStack.pop();
                group.historyStack.push(restoredValue);
                input.value = restoredValue;
                group.lastSavedValue = input.value;
            }
        }

        updateButtonsState(group);

        setTimeout(() => input.focus(), 0);
    });

    function updateButtonsState(group) {
        const undoBtn = group.querySelector(".undoBtn");
        const redoBtn = group.querySelector(".redoBtn");

        if (!group.historyStack) group.historyStack = [];
        if (!group.redoStack) group.redoStack = [];

        undoBtn.classList.toggle("none", group.historyStack.length <= 1);
        redoBtn.classList.toggle("none", group.redoStack.length === 0);
    }
});
