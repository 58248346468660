//  dropdown heder
const dropdown = document.querySelector(".dropdown");
const toggle = document.querySelector(".dropdown-toggle");

toggle.addEventListener("click", function(event) {
    event.stopPropagation();
    dropdown.classList.toggle("open");
});

document.addEventListener("click", function(event) {
    if (!dropdown.contains(event.target)) {
        dropdown.classList.remove("open");
    }
});

// swiper
var swiper = new Swiper(".mySwiper", {
    slidesPerView: 6,
    spaceBetween: 0,
    navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
  });

// height position of elements
const liElements = document.querySelectorAll('.tree-list li[id]');

const marginOffset = -318;

  liElements.forEach(li => {
    const rect = li.getBoundingClientRect();
    const heightFromTop = rect.top + window.scrollY;
    const viewportWidth = window.innerWidth;
    const scaleFactor = viewportWidth / 1920; 
    const adjustedMarginOffset = marginOffset * scaleFactor;
    const popupElements = document.querySelectorAll(`.customer_card[data-id="${li.id}"]`);
    popupElements.forEach(popupElement => {
      popupElement.style.marginTop = `${heightFromTop + adjustedMarginOffset}px`;
    });
  });
  
const marginOffset1 = -234;

liElements.forEach(li => {
  const rect = li.getBoundingClientRect();
  const heightFromTop = rect.top + window.scrollY;
  const viewportWidth = window.innerWidth;
  const scaleFactor = viewportWidth / 1920; 
  const adjustedMarginOffset = marginOffset1 * scaleFactor;
  const popupElements = document.querySelectorAll(`.customer_list_popup li[data-id="${li.id}"]`);
  popupElements.forEach(popupElement => {
    popupElement.style.marginTop = `${heightFromTop + adjustedMarginOffset}px`;
  });
});


// Popups appearing and disappearing
document.querySelectorAll(".customer_list_popup li").forEach(popupItem => {
  popupItem.addEventListener("mouseenter", function () {
      const id = this.dataset.id;
      document.querySelectorAll(`.tree-list li[id="${id}"]`).forEach(li => {
          li.classList.add("highlight");
          li.querySelectorAll(".circle").forEach(circle => {
              circle.style.backgroundColor = "#42ea6d"; 
              circle.style.color = "#000"; 
          });
      });
  });

  popupItem.addEventListener("mouseleave", function () {
      const id = this.dataset.id;
      document.querySelectorAll(`.tree-list li[id="${id}"]`).forEach(li => {
          li.classList.remove("highlight");
          li.querySelectorAll(".circle").forEach(circle => {
              circle.style.backgroundColor = ""; 
              circle.style.color = "";
          });
      });
  });
});


const togglePopup = (popup, nameElement, customerCard, isActive) => {
  popup.classList.toggle("active", isActive);
  nameElement.classList.toggle("highlight", isActive);
  customerCard.classList.toggle("hidden_card", isActive);

  document.querySelectorAll(".customer_card, .slider_arrows").forEach(element => {
    element.classList.toggle("dimmed", isActive);
  });
  document.querySelectorAll(".name").forEach(element => {
    if (element !== nameElement) {
      element.classList.toggle("dimmed", isActive);
    }
  });

  if (isActive) {
    setTimeout(() => updatePopupHeight(popup), 10);
  }
};

document.querySelectorAll(".popup_open").forEach(button => {
  button.addEventListener("click", function (e) {
    e.stopPropagation();

    const customerList = this.closest(".customer_list");
    const customerListPopup = customerList.querySelector(".customer_list_popup");
    const nameElement = customerList.querySelector(".name");
    const customerCard = customerList.querySelector(".customer_card");

    document.querySelectorAll(".customer_list_popup.active").forEach(popup => {
      const parentList = popup.closest(".customer_list");
      togglePopup(popup, parentList.querySelector(".name"), parentList.querySelector(".customer_card"), false);
    });

    if (customerListPopup) {
      togglePopup(customerListPopup, nameElement, customerCard, !customerListPopup.classList.contains("active"));
    }
  });
});

document.querySelectorAll(".close_popup").forEach(button => {
  button.addEventListener("click", function (e) {
    e.stopPropagation();

    const customerListPopup = this.closest(".customer_list_popup");
    if (customerListPopup) {
      const customerList = customerListPopup.closest(".customer_list");
      togglePopup(customerListPopup, customerList.querySelector(".name"), customerList.querySelector(".customer_card"), false);
    }
  });
});

document.addEventListener("click", function (e) {
  const customerListPopup = document.querySelector(".customer_list_popup.active");
  if (customerListPopup && !customerListPopup.contains(e.target) && !e.target.closest(".popup_open")) {
    const customerList = customerListPopup.closest(".customer_list");
    togglePopup(customerListPopup, customerList.querySelector(".name"), customerList.querySelector(".customer_card"), false);
  }
});

// line behind popup list
const updatePopupHeight = (popup) => {
    if (!popup) return;
    const items = popup.querySelectorAll("li");
    if (items.length === 0) return;
    let maxHeight = 0;
    items.forEach(item => {
        const bottomEdge = item.offsetTop + item.offsetHeight;
        if (bottomEdge > maxHeight) {
            maxHeight = bottomEdge;
        }
    });

    popup.style.height = `${maxHeight}px`;
    popup.style.backgroundSize = `1px ${maxHeight}px`;
};
